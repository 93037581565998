import React from "react";

function Habbit() {
  return (
    <div className="bg-white rounded-lg p-5 flex items-start text-left text-[18px] font-[400]">
      • Reading (Book, Online news), Communicating with Other People <br />
      • Researching(programming languages, New Technologies) <br />
      • Do Exercise, Gym and Fitness
    </div>
  );
}

export default Habbit;
